import React from 'react'
import Button from './Button'
import { useStaticQuery, graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import { ExternalLinkIcon } from '@heroicons/react/outline';

const FeatureImage = ({image, imageAlt, title, section, byline, theme, linkType, link, linkText}) => {


	let textColor, btnTextColor;

	const passedImg = image.substring((image.lastIndexOf('/')+1));

	let imgObj;

	const inspirationImgData = useStaticQuery(graphql`
    query inspirationImgData {
			allFile(filter: {sourceInstanceName: {eq: "featuredImages"}}) {
				edges {
					node {						
						childImageSharp {
							gatsbyImageData(
								width: 1400, 
								placeholder: BLURRED, 
								formats: [AUTO, WEBP, AVIF]
							)
						}
						relativePath
					}
				}
			}
		}
  `);

	for (let i = 0; i < inspirationImgData.allFile.edges.length; i++) {
		if (inspirationImgData.allFile.edges[i].node.relativePath === passedImg) {
			imgObj = inspirationImgData.allFile.edges[i].node;
			break;
		}
	}

	const backgroundImg = getImage(imgObj);
	const bgImage = convertToBgImage(backgroundImg);

	switch(theme) {
		case 'pink':
			textColor = 'white';
			btnTextColor = 'white'
			break;
		case 'yellow':
			textColor = 'white';
			btnTextColor = 'black'
			break;
		case 'teal':
			textColor = 'white';
			btnTextColor = 'black'
			break;
		case 'green':
			textColor = 'white';
			btnTextColor = 'black'
			break;
		case 'blue':
			textColor = 'white';
			btnTextColor = 'black'
			break;
		default:
			// code block
	}
	

	return (
		<BackgroundImage className={`border-b-4 border-${theme} bg-no-repeat bg-cover w-full relative overflow-hidden sm:h-96 h-72`} Tag="div" {...bgImage}>
		
			<div className="bg-gradient-to-t from-black w-full h-1/2 absolute bottom-0 left-0 z--1"></div>
			<div className="absolute sm:left-1/4 sm:w-1/2 left-3/20 w-2/3 bottom-6">
				<h6 className={`text-${theme} font-bold `}>{ section }</h6>
				{byline ? <span className={`text-${textColor} pb-4`}>{ byline }</span> : null}				
				<h3 className={`text-${textColor} font-bold lg:text-3xl md:text-2xl md:pb-8 pb-4 text-xl`}>{ title }</h3>
				{linkType === 'internal' ? <Button bgClassName={`bg-${theme}`} link={link} textClassName={`text-${btnTextColor}`}>{linkText}</Button> : <a href={ link } rel="noreferrer" target="_blank"  className={`btn cursor-pointer relative px-2 font-bold w-auto bg-${theme} text-${btnTextColor}`}>{ linkText }<ExternalLinkIcon className="h-8 w-8 inline-block pl-2" /></a>}
				
			</div>
		</BackgroundImage>
	)
}


export default FeatureImage;
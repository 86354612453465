import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const Tile = ({image, tag, title, blurb, link, index, calling}) => {

	// Get file from path
	const passedImg = image.substring((image.lastIndexOf('/')+1));

	let imgObj;

	const imgData = useStaticQuery(graphql`
    query imgObjects {
			allFile(filter: {sourceInstanceName: {eq: "workThmbs"}}) {
				edges {
					node {
						childImageSharp {
							gatsbyImageData(
								width: 800, 
								placeholder: BLURRED, 
								formats: [AUTO, WEBP, AVIF]
							)
						}
						relativePath
					}
				}
			}
		}
  `);

	for (let i = 0; i < imgData.allFile.edges.length; i++) {
		if (imgData.allFile.edges[i].node.relativePath === passedImg) {
			imgObj = imgData.allFile.edges[i].node;
			break;
		}
	}

	const backgroundImg = getImage(imgObj);
	const bgImage = convertToBgImage(backgroundImg);

	let gridClasses;
	// let indicatorClass = "bg-pink";
	if (index === 0) {
		gridClasses = " lg:row-span-2 lg:col-span-2 lg:h-full focus:outline-none focus:ring-4 focus:ring-pink "
	}
	else if ((index + 1) % 6 === 2 ||(index + 1) % 6 === 3 ) {
		// indicatorClass = "bg-teal"
		gridClasses = " lg:h-full lg:min-h-64 focus:outline-none focus:ring-4 focus:ring-teal "
	}
	else if ((index + 1) % 6 === 1) {
		// indicatorClass = "bg-yellow"
		gridClasses = " lg:row-span-2 lg:col-span-2 lg:h-full lg:min-h-128 focus:outline-none focus:ring-4 focus:ring-yellow "
	}
	else if ((index + 1) % 6 === 0) {
		// indicatorClass = "bg-purple"
		gridClasses = " lg:row-span-2 lg:col-span-2 lg:col-start-2 lg:h-full focus:outline-none focus:ring-4 focus:ring-purple "
	}
	else if ((index + 1) % 6 === 4 || (index + 1) % 6 === 5) {
		// indicatorClass = "bg-green"
		gridClasses = " lg:col-start-1 lg:h-full lg:min-h-64 focus:outline-none focus:ring-4 focus:ring-green "
	}

	if (calling === 'work') {
		return (
			<Link to={link} className={`tile w-full relative bg-top bg-center bg-cover bg-top h-64 lg:m-0 lg:w-full sm:m-auto sm:w-3/4 overflow-hidden${gridClasses}`}>
			<BackgroundImage className="w-full h-full block" Tag="div" {...bgImage}>
				
					<h5 className="text-white absolute bg-gray-700 uppercase px-2 font-bold">{ tag }</h5>
					<div className="blurb text-gray-900 absolute bg-white p-3">
						<h4>{ title }</h4>
						<p className="line-clamp-2">
							{ blurb }
						</p>
					</div>
				{/* <span className={`${indicatorClass} text-white absolute top-0 right-0 w-6 h-6 text-center`}>{index+1}</span> */}
			</BackgroundImage>
			</Link>
		)
	} else {
		return (
			<BackgroundImage className="tile w-full relative bg-top bg-center bg-cover h-64 lg:w-full sm:m-auto sm:w-3/4 overflow-hidden" Tag="div" {...bgImage}>
				<Link to={link} className="w-full h-full block">
					<h5 className="text-white absolute bg-gray-700 uppercase px-2 font-bold">{ tag }</h5>
					<div className="blurb text-gray-900 absolute bg-white p-3">
						<h4>{ title }</h4>
						<p className="line-clamp-2">
							{ blurb }
						</p>
					</div>
				</Link>
			</BackgroundImage>
		)
	}
	
}

export default Tile;
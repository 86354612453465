import React from 'react';
import Header from '../global/Header';
import Footer from '../global/Footer';
import Store from '../../utilities/store';

export default function HomeLayout({children}) {
	return (
		<Store>
			<div className="text-base bg-black">
				<Header />
					{ children  }
				<Footer />
			</div>
		</Store>
	)
}

import React from 'react'

const Blockquote = ({quote, citation, org, theme, borderPos}) => {

	let cite;

	if (citation && !org) {
		cite = <cite>{citation}</cite>
	}
	else if (citation && org) {
		cite = <cite>{citation}, <br />{org}</cite>
	}
	else if (!citation && org) {
		cite = <cite>{org}</cite>
	}

	let colorClassName = theme || 'teal';
	let borderPosClassName;
	borderPos ? borderPosClassName = 'border-' + borderPos + '-5' : borderPosClassName = 'border-t-5';
	let borderColorClassName;
	theme ? borderColorClassName = 'border-' + theme: borderColorClassName = 'border-teal';


	
	return (
		<div className={`${borderPosClassName} ${borderColorClassName}`}>
			<blockquote className={ colorClassName }>
				{ quote }
			</blockquote>
			{ cite }
		</div>
	)
}

export default Blockquote;
import React from "react"

const ClientLogoGrid = () => {
  return (
    <div className="grid md:grid-cols-5 sm:grid-cols-3 grid-cols-2 gap-4">
      <div className="flex justify-center">
        <img
          className="lg:w-3/4 w-1/2 m-auto"
          src="images/aus-gov-2.png"
          alt="Australian Govenment coat of arms"
        />
      </div>

      {/* 
      NOTE: If this gets uncommented, change md: grid-cols-5 to 6
      <div className="justify-center">
        <img
          className="lg:w-3/4 w-1/2 m-auto"
          src="images/csiro.png"
          alt="CSIRO logo"
        />
      </div> */}

      <div className="justify-center">
        <img
          className="lg:w-3/4 w-1/2 m-auto"
          src="images/usyd.png"
          alt="University of Sydney coat of arms"
        />
      </div>

      <div className="justify-center">
        <img
          className="lg:w-3/4 w-1/2 m-auto"
          src="images/uts.png"
          alt="University of Technology Sydney coat of arms"
        />
      </div>

      <div className="justify-center">
        <img
          className="lg:w-3/4 w-1/2 m-auto"
          src="images/westpac.png"
          alt="Westpac logo"
        />
      </div>

      <div className="justify-center">
        <img
          className="lg:w-3/4 w-1/2 m-auto"
          src="images/bupa.png"
          alt="Bupa logo"
        />
      </div>
    </div>
  )
}

export default ClientLogoGrid

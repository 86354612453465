import React from 'react'

const ProfileTile = ({name, title, linkedInLink, photo}) => {
	return (
		<div className="team-tile relative w-full h-auto">
			<img className="w-full relative" src={ photo } alt={ name } />
			<div className="h-1/4 z-30 absolute bottom-0 w-full bg-gray-800 bg-opacity-80 p-2">
				<h4 className="text-white">{ name }</h4>
				<p className="text-white leading-6">{ title }</p>
					<a href={ linkedInLink } target="_blank" rel="noreferrer" className="absolute right-3 top-1/3">                 
						<svg width="16px" height="16px" viewBox="0 0 16 16" className="transform scale-150">
							<title>Icon linkedin</title>
							<g id="iconLinkedIn" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
								<g transform="translate(0.000000, 0.000000)" fill="#FFFFFF" fillRule="nonzero">
									<polygon points="3.59689922 15.9379845 0.248062016 15.9379845 0.248062016 5.27131784 3.53488372 5.27131784 3.53488372 15.9379845"></polygon>
									<path d="M1.92248062,3.78294574 C0.868217053,3.78294574 0,2.91472869 0,1.86046512 C0,0.806201551 0.868217053,-5.96709016e-16 1.92248062,-5.96709016e-16 C2.97674419,-5.96709016e-16 3.84496124,0.868217053 3.84496124,1.92248062 C3.84496124,2.97674419 2.97674419,3.78294574 1.92248062,3.78294574 Z"></path>
									<path d="M16,15.9379845 L12.7131783,15.9379845 L12.7131783,10.7286822 C12.7131783,9.48837208 12.7131783,7.87596898 10.9767442,7.87596898 C9.24031011,7.87596898 8.99224808,9.24031011 8.99224808,10.6046512 L8.99224808,15.875969 L5.64341086,15.875969 L5.64341086,5.27131784 L8.80620155,5.27131784 L8.80620155,6.69767442 L8.86821706,6.69767442 C9.30232556,5.82945737 10.4186047,4.96124031 12.0310078,4.96124031 C15.379845,4.96124031 16,7.19379845 16,10.0465117 L16,15.9379845 Z"></path>
								</g>
							</g>
						</svg>  
					</a>
			</div>
		</div>
	)
}

export default ProfileTile;
import React from 'react'
import { Link } from 'gatsby'

const Button = (props) => {

	return (
		<Link className={`btn cursor-pointer relative px-2 font-bold flex-shrink w-auto ${props.bgClassName} ${props.textClassName}`} target={props.target} to={props.link}>
			{props.children}
		</Link>
	)
}

export default Button
